<template>
  <div>
    <el-table stripe :data="tableList">
      <el-table-column
        prop="id"
        label="事件编号"
        align="center"
        min-width="120"
      />
      <el-table-column prop="studentName" label="学生姓名" align="center" />
      <el-table-column prop="idCard" label="身份证号" align="center" />
      <el-table-column
        prop="guardianPhone"
        label="紧急联系人手机号"
        align="center"
      />
      <el-table-column prop="teacherName" label="监考人员" align="center" />
      <el-table-column prop="teacherPhone" label="监考手机号" align="center" />
      <el-table-column prop="groupName" label="监控组" align="center" />
      <el-table-column prop="categoryName" label="事件类型" align="center" />
      <el-table-column
        prop="eventProblemDescribe"
        label="详细描述"
        align="center"
      />
      <el-table-column prop="eventStatus" label="状态" align="center" />
      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          {{ formatHandle(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="120">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            style="color: #563279"
            @click="connectHandle(scope.row)"
            >通话
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="showVideoBox"
      title="通话"
      center
      width="1000px"
      :before-close="cancelVideoBox"
      :close-on-click-modal="false"
    >
      <div class="video-user-box">
        <div
          class="user-box"
          v-for="(item, index) in studentList"
          :class="'video-size' + item.videoSize"
          :key="index"
        >
          <div
            class="user-box1"
            v-for="(itemVideo, indexVideo) in userVideoList"
            :key="indexVideo"
          >
            <!--<img class="thussat_logo" src="../../../assets/image/logo.svg" alt=""/>-->
            <video
              :ref="
                'video_' +
                item.subjectUuid +
                '_' +
                item.zkzNum +
                '_' +
                itemVideo
              "
              :key="
                'video_' +
                item.subjectUuid +
                '_' +
                item.zkzNum +
                '_' +
                itemVideo
              "
              autoplay
              playsinline
              v-show="showVideoModel(itemVideo, item)"
            ></video>
            <div
              class="video_action"
              v-if="
                item[item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                  .is_join == 1
              "
            >
              <i
                class="el-icon-chat-dot-round"
                @click="msgShow(item, itemVideo)"
                v-if="!showVideo && (indexVideo == 0 || indexVideo == 1)"
              ></i>
              <template
                v-if="
                  item[item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                    .is_video == 1
                "
              >
                <i
                  :class="
                    item[item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                      .is_audio == 1
                      ? 'yd004laba-3'
                      : 'yd004laba-4'
                  "
                  class="ydfont"
                  v-if="indexVideo == 0 || indexVideo == 1"
                  @click="
                    subscribeAudio(
                      item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                      item
                    )
                  "
                ></i>
                <i
                  v-if="indexVideo == 0 || indexVideo == 1"
                  :class="
                    item[item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                      .is_call == 1
                      ? 'ydmaikefengxianxing'
                      : 'ydmaikefeng-jingyin'
                  "
                  class="ydfont"
                  @click="
                    callOneStudent(
                      item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo,
                      item
                    )
                  "
                ></i>
                <i
                  :class="showVideo ? 'ydicon--1' : 'ydicon--'"
                  class="ydfont"
                  @click="
                    showTopVideo(
                      item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                    )
                  "
                ></i>
              </template>
            </div>
            <div
              class="video_loading"
              v-show="!showVideo"
              :element-loading-text="
                item[item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                  .is_join == 1
                  ? '加载视频中'
                  : '未加入监控'
              "
              element-loading-spinner="el-icon-loading"
              v-loading="
                item[item.subjectUuid + '_' + item.zkzNum + '_' + itemVideo]
                  .is_video == 0
              "
            ></div>
          </div>
          <div class="user-info">
            <div class="user_text">
              <span>准考证号：</span>
              <span v-text="item.zkzNum"></span>
              <span>考生姓名：</span>
              <span v-text="item.name"></span>
            </div>
            <div class="user_action">
              <el-button type="primary" @click="onCommit(4)"
                >稍后处理</el-button
              >
              <el-button type="primary" @click="onCommit(1)"
                >未能处理</el-button
              >
              <el-button type="primary" @click="onCommit(2)">已处理</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 居家考消息 弹框 -->
    <msgDialog
      :dialogVisibleFu="dialogVisibleFu"
      :allSend="all_send"
      :userKey="userKey"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { videoMixin } from "mixins/jiDong/video";

export default {
  name: "WaitTask",
  mixins: [videoMixin],

  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "a/scss/page";

.video_box {
  display: none;

  .showVideo {
    display: none;
  }
}

.video-user-box {
  flex: 1;
  overflow: auto;
  margin-top: 20px;

  .user-box {
    &.video-size1 {
      > div {
        width: 100%;
      }
    }

    .thussat_logo {
      width: 20%;
      position: absolute;
      z-index: 999;
    }

    width: 100%;
    height: 400px;
    display: inline-block;
    margin: 0;
    background: #fff;
    border-radius: 6px;

    &:nth-child(2n) {
      margin-right: 0;
      margin-left: 0.5%;
    }

    > div {
      width: 32%;
      height: 80%;
      display: inline-block;
      position: relative;
      margin-right: 1%;
      border-radius: 6px;
      float: left;

      &.user-info {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 14px;
        height: auto;
        height: 18%;
        padding-left: 10px;
        margin-right: 0;
        font-family: "Source Han Sans CN";
        font-weight: 400;
        color: #333333;

        .user_text {
          span {
            &:nth-child(3) {
              margin-left: 10px;
            }
          }
        }
      }

      .text_btn1 {
        height: 20px;
        position: absolute;
        right: 20%;
        top: 50%;
        transform: translate(0, -50%);
      }

      .text_btn {
        height: 20px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.showVideo {
        > video {
          border: 1px dotted #ccc;
        }
      }

      > video {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 6px;
        position: absolute;
        top: 0;
        z-index: 2;
        left: 0;
      }

      .video_action {
        position: absolute;
        top: 8px;
        right: 2px;
        z-index: 3;

        .ydfont,
        .el-icon-chat-dot-round {
          padding: 2px;
          margin-right: 2px;
          display: inline-block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ffffff;
          border-radius: 50%;
          color: #563279;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .video_loading {
        position: absolute !important;
        top: 1px;
        height: 98%;
        z-index: 1;
        width: 98%;
        left: 1px;
        background-color: #ccc;
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }

  ::v-deep .el-loading-mask {
    border: 1px dotted #ccc;
    border-radius: 6px;
  }
}
</style>

