//监控人员相关
import 'aliyun-webrtc-sdk';
import {
    formatTime
} from "common/utils";
import {
    getEventList,
    updateEventProblem
} from "r/jiDong/eventHall";
import configApp from "config/index";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import localForage from "localforage";

import msgDialog from "c/index/examination/msgDialog";

export const videoMixin = {
    props: {
        eventTypeId: [String, Number],
        studentName: [String, Number],
    },
    components: {
        msgDialog,
    },
    data() {
        return {

            videoSize: 0,
            showVideoBox: false,
            hasScreenVideo: true, //是否展示共享屏幕
            showVideo: false,
            studentList: [],
            userInfo: {},
            zfzNum: '',
            stomp: {},
            socket: null,
            msgSingleObj: {}, // 发送的单个消息对象列表
            studentId: null, // 当前通话的学生id
            teacherId: null,
            teacherName: "",
            userVideoList: ["front", "back"],
            userVideoListScreen: ["front", "back", "screen"],
            userVideoListTest: {
                "front": "前置",
                "back": "后置"
            },
            userVideoListOne: ["front"],
            optionRtc: {
                isReceiveOnly: configApp.rtc.canCameraPublish,
            },
            tableList: [],
            queryParams: {
                pageNum: 1,
                pageSize: 100,
                status: 0,
                teacherId: null,
                eventTypeId: null,
                studentName: this.studentName
            },
            total: 0,
            dialogVisibleFu: false, // 控制发送消息弹框显示与隐藏
            dataInfo: {},
            userKey: '', // 当前发送消息的本地缓存key
            userType: '', // 正、反面监控  front-正面  back-反面
            all_send: 0, // 发送消息 0-单个 1-全体 2-评委
            refreshTimeArr: [1, 1, 2, 2, 2, 4, 4, 4, 4, 8, 8, 8, 8, 8, 16, 16, 16, 16, 16, 16, 20], //重新连接的时间间隔，单位秒，最大的是默认是20s
            refreshSocketTimeout: 0, //自动重连的定时器
            refreshSocket: 0, //自动重连的定时器
        };
    },
    created() {
        let teacherId = window.localStorage.getItem('jiDong-teacherId');
        this.queryParams.teacherId = teacherId
        this.teacherId = teacherId;
    },
    mounted() {
        let aliWebrtc = new AliRtcEngine();
        this.aliWebrtc = aliWebrtc;
        /**
         * AliWebRTC isSupport检测
         */
        aliWebrtc.enableCamera = configApp.rtc.canCameraPublish;
        aliWebrtc.isSupport(this.optionRtc).then(re => {
            this.getList();
            this.initSocket();
        }).catch(error => {
            this.$notify.error({
                title: '错误',
                message: error.message
            });
        })
    },
    methods: {
        showVideoModel(type, item) {
            let status = true;
            if (type == 'screen' && item.screen_active == 0) {
                status = false;
            }
            return status;
        },
        // 关闭通话弹框
        cancelVideoBox() {
            this.showVideoBox = false;
        },
        // 通话弹框 未能处理-1 已处理-2
        onCommit(val) {
            let data = {};
            data.id = this.studentId;
            data.status = val;
            data.teacherIdSolver = this.teacherId;
            updateEventProblem(data)
                .then(res => {
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }
                    this.$message.success(res.data.message);
                    this.cancelVideoBox();
                    this.getList();
                    this.$emit('onCommitTop')
                })
                .catch(err => {
                    console.log(err)
                })
        },
        formatHandle(val) {
            if (val) {
                return formatTime(val);
            }
            return '-';
        },
        //初始化aliwebrtc对象
        // 通话
        connectHandle(row) {
            console.log(row)
            this.studentId = row.id;
            let data = {};
            data.id = row.id;
            data.status = 0;
            data.teacherIdSolver = this.teacherId;
            updateEventProblem(data)
                .then(res => {
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }
                    let list = res.data.data;
                    this.showVideoBox = true;
                    let userInfo = list[0];

                    this.teacherName = userInfo.username;
                    let videoSize = userInfo.videoSize;
                    if (videoSize === 1) {
                        this.userVideoList = this.userVideoListOne;
                    }
                    if (this.hasScreenVideo) {
                        this.videoSize = this.videoSize + 1;
                        this.userVideoList = this.userVideoListScreen;
                    }
                    this.studentList = [];
                    let studentList = [];
                    let config = JSON.parse(userInfo.token);
                    let channelId = config.channelId;
                    let data = userInfo[channelId];
                    this.zfzNum = data[0].zkzNum;
                    let tempData = [];
                    let userVideoList = this.userVideoList;
                    for (let j = 0; j < data.length; j++) {
                        let temp = data[j];
                        temp["subjectUuid"] = userInfo.subjectUuid;
                        let fix = userInfo.subjectUuid + '_' + temp.zkzNum;
                        for (let v = 0; v < userVideoList.length; v++) {
                            temp[fix + '_' + userVideoList[v]] = {
                                is_join: 0,
                                is_video: 0,
                                is_call: 0,
                                is_audio: 0
                            };
                        }
                        temp['screen_active'] = 0;
                        tempData.push(temp);
                    }
                    studentList = studentList.concat(tempData);
                    //加入房间
                    this.joinRoom(config);
                    this.initRtc();
                    this.studentList = studentList;
                })
                .catch(err => {
                    console.log(err)
                })
        },
        /**
         * 加入房间
         * 触发：输入房间号、单击加入房间按钮
         * 更新页面信息
         * 默认开启预览
         * 获取鉴权信息
         * 加入房间
         * 本地默认自动推视频流（视频流 + 音频流）
         * 发布本地流
         */
        joinRoom(config) {
            let aliWebrtc = this.aliWebrtc;
            let userName = '机动人员_' + this.teacherId;
            //2. 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
            let authInfo = {};
            authInfo.appid = config.appid;
            authInfo.userid = config.userid;
            authInfo.timestamp = config.timestamp;
            authInfo.nonce = config.nonce;
            authInfo.token = config.token;
            authInfo.gslb = config.gslb;
            authInfo.channel = config.channelId;
            aliWebrtc.joinChannel(authInfo, userName).then(() => {
                this.publishAudio();
            }).catch((error) => {
                // console.log("[监控加入房间失败]" + error.message);
            })
        },
        publishAudio(cb = null) {
            let aa = setTimeout(() => {
                aa && clearTimeout(aa);
                let aliWebrtc = this.aliWebrtc;
                aliWebrtc.configLocalAudioPublish = true;
                aliWebrtc.configLocalCameraPublish = false;
                aliWebrtc.configLocalScreenPublish = false;
                aliWebrtc.enableCamera = false; //设置是否允许使用摄像头
                aliWebrtc.publish().then((res) => {
                    cb && cb(true);
                }).catch((error) => {
                    cb && cb(false);
                });
            }, 100)

        },
        checkUserId(userId) {
            if (!userId) {
                return false;
            }
            let info = {};
            let userArr = userId.split('_');
            if (userArr.length === 0) {
                return false;
            }
            if (userArr[1] !== this.zfzNum) {
                return false;
            }
            //学生
            if (userArr.length === 3) {
                info.type = 1;
                let videoType = userArr[2];
                info.videoType = videoType;
                let index = this.userVideoList.indexOf(videoType);
                if (index > -1) {
                    let userVideoList = JSON.parse(JSON.stringify(this.userVideoList));
                    delete userVideoList[index];
                    userVideoList = userVideoList.sort();
                    info.videoToType = userVideoList[0] ? userVideoList[0] : this.userVideoList[index];
                    info.videoToUserId = userArr[0] + "_" + userArr[1] + "_" + info.videoToType;
                }
            }
            //监控老师
            if (userArr.length === 2) {
                info.type = 2;
            }
            info.userId = userId;
            return info;
        },

        getList() {
            getEventList(this.queryParams)
                .then(res => {
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }
                    this.tableList = res.data.data.records;
                    this.total = res.data.data.total;
                    this.$emit('onChild', this.total);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 控制每页显示几条数据
        handleSizeChange(newSize) {
            this.queryParams.pageSize = newSize;
            this.getList();
        },
        // 控制显示第几页
        handleCurrentChange(newNum) {
            this.queryParams.pageNum = newNum;
            this.getList();
        },
        //aliwebrtc的事件监听
        initRtc() {
            let aliWebrtc = this.aliWebrtc;
            /**
             * remote用户加入房间 onJoin
             * 更新在线用户列表
             */
            aliWebrtc.on("onJoin", (publisher) => {
                let userId = publisher.userId;
                console.log(userId)
                let userInfo = this.checkUserId(userId);
                if (userInfo.type === 1) {
                    this.updateUserJoinList(userId);
                }
            });
            /**
             * remote流发布事件 onPublish
             * 将该用户新增到推流列表
             * 若该用户已存在推流列表，则进行状态更新
             */
            aliWebrtc.on("onPublisher", (publisher) => {
                console.log('onPublisher', publisher)
                let userId = publisher.userId;
                let userInfo = this.checkUserId(userId);
                if (userInfo.type === 1) {
                    let streamConfigs = publisher.streamConfigs;
                    let screen_active = 0;
                    streamConfigs.forEach((item) => {
                        if (item.label == 'sophon_video_screen_share' &&
                            item.state == 'active') {
                            screen_active = 1;
                        }
                    })
                    console.log('进行状态更新')
                    this.subscribeUser(userId, screen_active);
                }
            });

            /**
             * remote流结束发布事件 onUnPublisher
             * 推流列表删除该用户
             * 移除用户视图
             * 初始化订阅状态
             */
            aliWebrtc.on("onUnPublisher", (publisher) => {
                let userId = publisher.userId;
                let userInfo = this.checkUserId(userId);
                if (userInfo.type === 1) {
                    this.unSubscribePublisher(userId);
                }
            });

            /**
             * 被服务器踢出或者频道关闭时回调 onBye
             */
            aliWebrtc.on("onBye", (message) => {
                //1:被服务器踢出
                //2:频道关闭
                //3:同一个ID在其他端登录,被服务器踢出
                let msg;
                switch (message.code) {
                    case 1:
                        msg = "被服务器踢出";
                        // 清除本地的 token
                        //window.localStorage.clear();
                        // 使用编程式导航跳转到登录页面
                        //this.$router.push("/login");
                        break;
                    case 2:
                        msg = "频道关闭";
                        break;
                    case 3:
                        msg = "同一个ID在其他端登录,被服务器踢出";
                        break;
                    default:
                        msg = "onBye";
                }
                // console.log(msg);
            });

            /**
             *  错误信息
             */
            aliWebrtc.on("onError", (error) => {
                //this.getList();
                let msg = error && error.message ? error.message : error;
                if (msg && msg.indexOf("no session") > -1) {
                    msg = "请重新登录：" + msg;
                }
                if (error.errorCode === 10011 || error.errorCode === 10012) {
                    msg = error.errorCode === 10011 ? "屏幕共享被禁止" : "屏幕共享已取消";
                    // setTimeout(() => {
                    //   $("#screenPublish").removeAttr("checked");
                    //   getPublishState("danger");
                    // }, 2000);
                }

                if (error.code == 15) {
                    msg = "没有开启H5兼容";
                }
                if (error.type === "publish") {
                    // 提示用户网络状态不佳
                    // console.log("推流断开 需要停止推流,然后重新推流");
                }
                if (error.type === "subscribe") {
                    //console.log("订阅断开 取消订阅该userId的所有订阅并移除所有该userId的dom");
                    //先记录当前用户的订阅状态
                    //let subInfo = this.getSubscribeInfo(index,error.userId);
                    //取消订阅状态
                    let userId = error.userId;
                    let userInfo = this.checkUserId(userId);
                    if (userInfo.type === 1) {
                        let student = this.studentList[0];
                        if (student && student[userId].is_video) {
                            this.subscribeUser(userId, student.screen_active);
                        }
                    }
                }
            });

            /**
             * 检测到用户离开频道
             * 更新用户列表
             * 移除用户视图
             */
            aliWebrtc.on("onLeave", (publisher) => {
                let userId = publisher.userId;
                this.delSubscribe(userId, 1);
            })
        },
        //学生加入频道 更新学生信息
        updateUserJoinList(userId) {
            let student = this.studentList[0];
            if (student && student[userId].is_join == 0) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                this.studentList[0][userId] = student[userId];
                this.$forceUpdate();
            }
        },
        //初始化 webSocket
        initSocket() {
            if (this.refreshSocketTimeout) {
                return true;
            }
            if (typeof (WebSocket) == "undefined") {
                this.$message({
                    showClose: true,
                    message: '您的浏览器不支持WebSocket',
                    type: 'error'
                });
            } else {
                let socket;
                if (!this.socket) {
                    let url = configApp.socket.base_url;
                    socket = new SockJS(url, null, {
                        timeout: configApp.socket.timeout
                    });
                    this.socket = socket;
                } else {
                    socket = this.socket;
                }
                let stomp = Stomp.over(socket);
                stomp.debug = null;
                this.stomp = stomp;
                // 每隔30秒做一次心跳检测
                stomp.heartbeat.outgoing = configApp.socket.outgoing;
                // 客户端不接收服务器的心跳检测
                stomp.heartbeat.incoming = configApp.socket.incoming;
                const user = {
                    'username': this.teacherId,
                    'token': localStorage.getItem("jiDong-token"),
                    'type': 't'
                };
                //连接
                stomp.connect(user, (frame) => {
                    //订阅广播
                    // stomp.subscribe("topic/chatRoom", function (res) {
                    //     console.log("top/chatRoom");
                    //     console.log(res.body);
                    // });
                    this.refreshSocket = 0;
                    this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                    //用户模式
                    stomp.subscribe("/user/" + this.teacherId + "/subChat", (res) => {
                        let data = JSON.parse(res.body);
                        if (data.code == 200) {
                            let message = JSON.parse(data.data.message);
                            message.isRead = 0;
                            message.id = data.data.messageId;
                            let messageType = message.type;
                            switch (messageType) {
                                case 1:
                                    this.messageUnCount = ++this.messageUnCount;
                                    this.messageList.unshift(message);
                                    this.noMessage = false;
                                    break;
                                case 2:
                                    let userId = data.data.user.username;
                                    // console.log(user_info);
                                    break;
                            }

                            // console.log(this.messageUnCount, "消息条数")
                        }
                    });
                }, () => {
                    this.refreshSocket = ++this.refreshSocket;
                    let refreshTimeArr = this.refreshTimeArr;
                    let time = refreshTimeArr[this.refreshSocket - 1];
                    time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                    this.refreshSocketTimeout = setTimeout(async () => {
                        this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                        this.refreshSocketTimeout = null;
                        this.socket = null;
                        this.initSocket();
                    }, time * 1000);
                });
            }
        },
        //发送Socket 消息
        sendMessage(cmd, message, receiver = '') {
            let data = {
                "message": message
            };
            if (receiver) {
                data.receiver = receiver;
            }
            data.subjectUuid = this.subjectUuid;
            data = JSON.stringify(data);
            // console.log(data)
            this.stomp.send(cmd, {}, data);
        },
        /**
         * 频道老师是否订阅
         * @param userId 用户id
         * @param student 用户信息
         * @returns {boolean}
         */
        subscribeUser(userId, screen_active = 0, student = false) {
            console.log('订阅')
            console.log(userId, 'userId')
            window.localStorage.setItem('nowUserId', userId)
            if (!student) {
                student = this.studentList[0];
            }
            this.subscribeScreenVideo(userId, screen_active, student);
            let video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0] : false;
            if (video) {
                let aliWebrtc = this.aliWebrtc;
                //前置摄像头
                aliWebrtc.configRemoteCameraTrack(userId, true, true);
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                aliWebrtc.configRemoteAudio(userId, true);
                aliWebrtc.setAudioVolume(userId, 1);
                aliWebrtc.subscribe(userId).then(res => {
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        1 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[userId].is_join = 1;
                    student[userId].is_video = 1;
                    student[userId].is_audio = 0;
                    this.studentList[0][userId] = student[userId];
                    this.$forceUpdate();
                }).catch((error) => {
                    this.updateUserJoinListFail(userId)
                    console.log(error);
                })
            }
        },
        // 订阅共享屏幕
        subscribeScreenVideo(userId, screen_active, student) {
            let arr = userId.split('_');
            if (!arr[2] || (arr[2] && arr[2] != "back")) {
                return false;
            }
            let newUserId = arr[0] + "_" + arr[1] + "_screen";
            this.studentList[0]['screen_active'] = screen_active;
            if (screen_active != 1) {
                this.updateUserJoinListFail(newUserId)
                return false;
            }
            let video = this.$refs['video_' + newUserId] ? this.$refs['video_' + newUserId][0] : false;
            console.log(video)
            if (video) {
                let aliWebrtc = this.aliWebrtc;
                //前置摄像头
                aliWebrtc.configRemoteScreenTrack(userId, true);
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                aliWebrtc.configRemoteAudio(userId, true);
                aliWebrtc.setAudioVolume(userId, 1);
                aliWebrtc.subscribe(userId).then(res => {
                    console.log(res)
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        2 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[newUserId].is_join = 1;
                    student[newUserId].is_video = 1;
                    student[newUserId].is_audio = 0;
                    this.studentList[0][newUserId] = student[newUserId];
                    console.log(this.studentList[0])
                    this.$forceUpdate();
                }).catch((error) => {
                    this.updateUserJoinListFail(newUserId)
                    console.log(error);
                })
            }
        },
        // 取消订阅
        unSubscribeUser(userId, student = false) {
            if (!student) {
                student = this.studentList[0];
            }
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtc;
                //前置摄像头
                aliWebrtc.configRemoteCameraTrack(userId, false, false);
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                aliWebrtc.configRemoteAudio(userId, false);
                aliWebrtc.setAudioVolume(userId, 1);
                aliWebrtc.subscribe(userId).then(res => {}).catch((error) => {
                    console.log(error, "userId:" + userId, "channelId:" + channelId, "unSubscribeUser");
                })
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                let arr = userId.split('_');
                let newUserId = '';
                if (arr && arr[2] == 'back') {
                    newUserId = arr[0] + "_" + arr[1] + "_screen";
                    student[newUserId].is_video = 0;
                    student[newUserId].is_audio = 0;
                }
                this.studentList[0] = student;
            }
        },
        delSubscribeScreen(userId) {
            let arr = userId.split('_');
            if (!arr[2] || (arr[2] && arr[2] != "back")) {
                return false;
            }
            let newUserId = arr[0] + "_" + arr[1] + "_screen";
            this.delSubscribe(newUserId, 2);
        },
        //取消订阅状态
        delSubscribe(userId, type) {
            // this.delSubscribeScreen(userId);
            let student = this.studentList[0];
            console.log(student)
            // console.log(userId)
            // console.log(student[userId].is_join)
            //  志愿者如果更改无论那个考生面试状态，都会推送过来该考生userId，
            // 此处需要判断当前打开通话的考生是否和推流过来的考生是同一个人
            let open_now_userId = window.localStorage.getItem('nowUserId')
            if (userId !== open_now_userId) {
                return false
            }else{
                if (student) {
                    if (type == 1) {
                        console.log(student[userId].is_join)
                        student[userId].is_join = 0;
                        student[userId].is_video = 0;
                        student[userId].is_call = 0;
                        student[userId].is_audio = 0;
                    } else {
                        let newUserId = userId;
                        student[newUserId].is_join = 0;
                        student[newUserId].is_video = 0;
                        student[newUserId].is_call = 0;
                        student[newUserId].is_audio = 0;
                    }
                    this.studentList[0] = student;
                    this.$forceUpdate();
                }
            }
        
        },
        //学生加入频道-失败 更新学生信息
        updateUserJoinListFail(userId) {
            let student = this.studentList[0];
            if (student && student[userId].is_join == 1) {
                student[userId].is_join = 0;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                student[userId].fps_times = 0;
                this.studentList[0][userId] = student[userId];
                this.$forceUpdate();
            }
        },
        //订阅用户的音频
        subscribeAudio(userId) {
            let student = this.studentList[0];
            let is_audio = student[userId].is_audio;
            is_audio = is_audio ? 0 : 1;
            let aliWebrtc = this.aliWebrtc;
            aliWebrtc.muteRemoteAudioPlaying(userId, !is_audio);
            aliWebrtc.setAudioVolume(userId, 1);
            this.studentList[0][userId].is_audio = is_audio;
            this.$forceUpdate();
        },
        unSubscribeScreenVideo(userId) {
            let arr = userId.split('_');
            if (!arr[2] || (arr[2] && arr[2] != "back")) {
                return false;
            }
            let newUserId = arr[0] + "_" + arr[1] + "_screen";
            this.unSubscribePublisher(newUserId);
        },
        //用户掉线取消订阅
        unSubscribePublisher(userId) {
            this.unSubscribeScreenVideo(userId);
            let student = this.studentList[0];
            if (student) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                this.studentList[0] = student;
                this.$forceUpdate();
            }
        },
        //和单个学生通话 开启或关闭和学生建立连接
        callOneStudent(userId) {
            let student = this.studentList[0];
            let is_call = student[userId].is_call;
            is_call = is_call ? 0 : 1;
            let aliWebrtc = this.aliWebrtc;
            aliWebrtc.muteLocalCamera(!is_call);
            aliWebrtc.muteLocalMic(!is_call, false);
            this.publishAudio((res) => {
                if (res) {
                    this.teacherPublishUpStudentCall(userId, student, is_call);
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: '你的声音发布失败，请刷新重新重试'
                    });
                }
            })
        },
        /**
         * 老师发布或关闭音频流 更新学生状态和发送socket
         * @param userId 学生id
         * @param student 学生信息
         * @param is_call 是否请求通话 0-关闭通话 1-请求通话
         * @param send_socket 是否发送socket
         */
        teacherPublishUpStudentCall(userId, student, is_call, send_socket = true) {
            let channelId = student['channelId'];
            this.studentList[0][userId].is_call = is_call;
            let aliWebrtc = this.aliWebrtc;
            aliWebrtc.muteLocalCamera(!is_call);
            aliWebrtc.muteLocalMic(!is_call, false);
            if (send_socket) {
                let message = {
                    channelId: channelId,
                    teacherId: this.teacherName,
                };
                message.type = is_call;
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message, [userId]);
            }
            this.$forceUpdate();
        },

        //视频列表 视频放大事件
        showTopVideo(userId) {
            let video;
            video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0].parentNode : false;
            // let student = this.studentList[0];
            if (this.showVideo) {
                if (window.document.exitFullscreen) {
                    window.document.exitFullscreen();

                } else if (window.document.mozCancelFullScreen) {
                    window.document.mozCancelFullScreen();

                } else if (window.document.webkitCancelFullScreen) {
                    window.document.webkitCancelFullScreen();

                } else if (window.document.msExitFullscreen) {
                    window.document.msExitFullscreen();
                }
            } else {
                try {
                    if (video) {
                        if (video.requestFullscreen) {
                            video.requestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.msRequestFullscreen) {
                            video.msRequestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.mozRequestFullScreen) {
                            video.mozRequestFullScreen();
                            // this.imgwidth = 50
                        } else if (video.webkitRequestFullscreen) {
                            video.webkitRequestFullscreen();
                            // this.imgwidth = 50
                        }

                    }

                } catch (e) {

                }
            }
        },
        //监听视频放大缩小改变的事件
        fullScreenChangeHandler() {
            this.showVideo = !this.showVideo;
        },
        // 打开发送消息弹框
        msgShow(data, userType) {
            let userKey = "jd_" + this.teacherId + data.subjectUuid;
            // 单个
            this.all_send = 0;
            this.dataInfo = data;
            userKey += '_' + this.dataInfo.zkzNum + '_' + userType;
            this.userKey = userKey;
            this.userType = userType;
            this.dialogVisibleFu = true;
        },
        // 关闭弹框
        closeDialog(value, type, msg) {
            this.dialogVisibleFu = value;
            if (type === true) {
                this.sendAllStudentMessage(msg);
            }
        },
        // //发送给所有学生
        sendAllStudentMessage(msg) {
            let message = {};
            let userId = "";
            let msgInfo = {};
            message.msg = msg;
            msgInfo.msg = msg;
            // 单个
            message.type = 3;
            userId = this.dataInfo.subjectUuid + '_' + this.dataInfo.zkzNum + '_' + this.userType;
            message = JSON.stringify(message);
            this.sendMessage('/pubChat', message, [userId]);
            msgInfo.time = formatTime(new Date());
            if (this.msgSingleObj[this.dataInfo.zkzNum]) {
                this.msgSingleObj[this.dataInfo.zkzNum].push(msgInfo);
            } else {
                this.msgSingleObj[this.dataInfo.zkzNum] = [];
                this.msgSingleObj[this.dataInfo.zkzNum].push(msgInfo);
            }
            let dataMessage = {
                "message": msg,
                "time": formatTime(new Date()),
            }
            localForage.getItem(this.userKey).then((list) => {
                if (list) {
                    list.push(dataMessage);
                } else {
                    list = [dataMessage];
                }
                localForage.setItem(this.userKey, list).then(() => {});
            });
        },
    },
    activated() {

    },
    watch: {
        eventTypeId() {
            this.queryParams.eventTypeId = this.eventTypeId;
        },
        studentName() {
            this.queryParams.studentName = this.studentName;
        },
    },

    destroyed() {
        this.aliWebrtc.dispose();
        this.socket && this.socket.close();
    }

};