import axios from "axios";
import {
    SERVER_errorURL,
    DEVELOPMENT_SERVER_errorURL
} from '../../config/server'

import {
    Notification,
    Message
} from "element-ui";
import router from "../../router";
export function request(config) {
    const instance = axios.create({
        baseURL: process.env.NODE_ENV === "development" ?
            DEVELOPMENT_SERVER_errorURL :
            SERVER_errorURL,
        timeout: 60 * 1000,
    });

    instance.interceptors.request.use(
        (config) => {
            let token = localStorage.getItem("jiDong-token");
            if (token) {
                config.headers.Authorization = "Bearer " + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            if (res.data.code === 401 && res.data.message === "暂未登录或token已经过期") {
                // 未登录
                Notification({
                    message: "暂未登录或token已经过期，请登录",
                    duration: 1500,
                    onClose() {
                        window.localStorage.clear();
                        router.replace("/jiDong/login");
                    },
                });
                return res;
            }
            return res;
        },
        (error) => {
            Message.closeAll();
            return Promise.reject(error);
        }
    );

    return instance(config);
}

// 合并多个请求
export function allRequest(arrRequest) {
    return axios.all(arrRequest);
}
