<template>
    <el-container>
        <el-main class="main-box">
            <div class="box-header-btn">
                <el-button :class="isSelect?'is-select':''" @click="changeTable(true)">待处理(<span>{{total1}}</span>)
                </el-button>
                <el-button :class="isSelect?'':'is-select'" @click="changeTable(false)">已处理(<span>{{total2}}</span>)
                </el-button>

                <el-button icon="el-icon-refresh" style="float: right" @click="onRefresh">刷新</el-button>
            </div>
            <!-- card面板 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <!-- 头部标题与form表单 -->
                    <el-row :gutter="20">
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6" style="padding: 0;">
                            <span>事件列表</span>
                        </el-col>
                        <el-input
                                class="search"
                                v-model="studentName"
                                placeholder="请输入学生姓名"
                        >
                            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
                        </el-input>
                        <el-select v-model="eventTypeId" class="search" placeholder="全部类型" clearable @change="onChange">
                            <el-option
                                    v-for="item in categoryList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-row>
                </div>
                <div class="text item" v-show="isSelect">
                    <wait-task
                            ref="waitTask"
                            :eventTypeId="eventTypeId"
                            :studentName="studentName"
                            @onChild="onChild1"
                            @onCommitTop="onCommitTop"
                    ></wait-task>
                </div>

                <div class="text item" v-show="!isSelect">
                    <yet-task
                            ref="yetTask"
                            :eventTypeId="eventTypeId"
                            :studentName="studentName"
                            @onChild="onChild2"
                    ></yet-task>
                </div>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
    import {getCategory} from "r/jiDong/eventHall.js";
    import WaitTask from "c/jiDong/eventHall/WaitTask";
    import YetTask from "c/jiDong/eventHall/YetTask";

    export default {
        name: "EventHall",
        components: {
            WaitTask,
            YetTask
        },
        data() {
            return {
                isSelect: true,// true-待处理 false-已处理
                categoryList: [],// 事件类型列表
                eventTypeId: null,//事件类型
                studentName: null,//学生姓名
                total1: 0,
                total2: 0,
            }
        },
        created() {
            this.getCategoryList();
        },
        methods: {
            onCommitTop(){
                this.$refs['yetTask'].onHandle();
            },
            onChild1(val) {
                this.total1 = val;
            },
            onChild2(val) {
                this.total2 = val;
            },
            onSearch() {
                if (this.isSelect) {
                    this.$refs['waitTask'].getList();
                } else {
                    this.$refs['yetTask'].onHandle();
                }
            },
            onChange(val) {
                if (this.isSelect) {
                    this.$refs['waitTask'].queryParams.eventTypeId = val;
                    this.$refs['waitTask'].getList();
                } else {
                    this.$refs['yetTask'].queryParams.eventTypeId = val;
                    this.$refs['yetTask'].onHandle();
                }
            },
            // 获取事件类型列表
            getCategoryList() {
                getCategory({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.categoryList = res.data.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 刷新
            onRefresh() {
                window.location.reload();
            },
            // 切换待处理、已处理表格
            changeTable(val) {
                this.isSelect = val;
                this.eventTypeId = null;
                this.studentName = null;
                if (this.isSelect) {
                    this.$refs['waitTask'].queryParams.eventTypeId = null;
                    this.$refs['waitTask'].getList();
                } else {
                    this.$refs['yetTask'].queryParams.eventTypeId = null;
                    this.$refs['yetTask'].getList();
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "a/scss/jiDongCommon";
    @import "a/scss/table";

    ::v-deep .el-table__header-wrapper {
        height: auto !important;
    }
</style>
