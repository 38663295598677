import { request } from "./network";

// 获取事件列表-事件大厅
export function getEventList(params) {
    return request({
        method: "get",
        url: "/eventProblem/listAll",
        params,
    });
}
// 获取事件类型列表-事件大厅
export function getCategory(params) {
    return request({
        method: "get",
        url: "/eventProblem/getCategory",
        params,
    });
}
export function updateEventProblem(params) {
    return request({
        method: "put",
        url: "/eventProblem/updateEventProblem",
        params,
    });
}
