<template>
    <div>
        <el-table
                stripe
                :data="tableList"
        >
            <el-table-column prop="id" label="事件编号" align="center" min-width="120"/>
            <el-table-column prop="studentName" label="学生姓名" align="center" />
            <el-table-column prop="idCard" label="身份证号" align="center"/>
            <el-table-column prop="guardianPhone" label="紧急联系人手机号" align="center"/>
            <el-table-column prop="teacherName" label="监考人员" align="center" />
            <el-table-column prop="teacherPhone" label="监考手机号" align="center" />
            <el-table-column prop="groupName" label="监控组" align="center" />
            <el-table-column prop="categoryName" label="事件类型" align="center" />
            <el-table-column prop="eventProblemDescribe" label="详细描述" align="center" />
            <el-table-column prop="createTime" label="创建时间" align="center" >
                <template slot-scope="scope">
                    {{formatHandle(scope.row.createTime)}}
                </template>
            </el-table-column>
            <el-table-column prop="processTime" label="领取时间" align="center" >
                <template slot-scope="scope">
                    {{formatHandle(scope.row.processTime)}}
                </template>
            </el-table-column>
            <el-table-column prop="solverTime" label="已解决时间" align="center" >
                <template slot-scope="scope">
                    {{formatHandle(scope.row.solverTime)}}
                </template>
            </el-table-column>
            <el-table-column prop="solverName" label="处理人" align="center" />
        </el-table>

        <!-- 分页器 -->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="queryParams.pageSize"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="queryParams.pageNum"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
    import {getEventList} from "r/jiDong/eventHall.js";
    import {formatTime} from "common/utils";

    export default {
        props:{
            eventTypeId: [String, Number],
            studentName: [String, Number],
        },
        name: "YetTask",
        data() {
            return {
                tableList: [],
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    status: 1,
                    teacherId: null,
                    studentName: this.studentName
                },
                total: 0
            }
        },
        created() {
            this.queryParams.teacherId = window.localStorage.getItem('jiDong-teacherId');
            this.getList();
        },
        methods: {
            formatHandle(val){
                if(val){
                    return formatTime(val);
                }
                return '-';
            },
            onHandle(){
                this.queryParams.pageNum = 1;
                this.getList();
            },
            getList() {
                getEventList(this.queryParams)
                    .then(res => {
                        console.log(res)
                        if(res.data.code != 200){
                            return this.$message.error(res.data.message);
                        }
                        this.tableList = res.data.data.records;
                        this.total = res.data.data.total;
                        this.$emit('onChild', this.total);
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 控制每页显示几条数据
            handleSizeChange(newSize) {
                this.queryParams.pageSize = newSize;
                this.getList();
            },
            // 控制显示第几页
            handleCurrentChange(newNum) {
                this.queryParams.pageNum = newNum;
                this.getList();
            },
        },
        watch:{
            eventTypeId(){
                this.queryParams.eventTypeId = this.eventTypeId;
            },
            studentName(){
                this.queryParams.studentName = this.studentName;
            },
        }
    };
</script>

<style scoped lang="scss">
    @import "a/scss/page";
</style>

